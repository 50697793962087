import React from "react";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert"; // Import
import { AxiosService } from "./service/axios.service";
import { AuthService } from "./service/auth.service";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Web } from "./web/index";
import "./App.css";

let auth = new AuthService();

function App() {
  const [cookie, setCookie, removeCookie] = useCookies(["session"]);
  let authenticated: boolean = cookie.session != null;
  let session: any = cookie.session != null;

  const login = (id: any, pw: any) => {
    auth.doLogin(id, pw).then((s) => {
      if (s.status === "success") {
        if (cookie.session != null) {
          removeCookie("session", { path: "/" });
        }
        setCookie("session", s.session, { path: "/" });
        setCookie("name", id, { path: "/" });
        window.location.href = "/";
      } else if (s.status === "wait") {
        confirmAlert({
          title: "로그인 ",
          message: "가입대기중 아이디 입니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      } else {
        confirmAlert({
          title: "로그인 ",
          message: "아이디 또는 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                removeCookie("session", { path: "/" });

                window.location.reload();
              },
            },
          ],
        });
      }
    });
  };

  const logout = () => {
    confirmAlert({
      title: "로그아웃 ",
      message: "로그아웃 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            removeCookie("session", { path: "/" });
          },
        },
      ],
    });
  };

  AxiosService.SetSession(cookie.session);

  return (
    <>
      <Web
        tryLogin={login}
        authenticated={authenticated}
        session={session.session}
        tryLoginOut={() => {
          removeCookie("session", { path: "/" });
        }}
        SetCookie={(name: string, data: any) =>
          setCookie(name, data, { path: "/" })
        }
        GetCookie={(name: string) => cookie[name]}
      ></Web>
      {authenticated &&
        document.write(`<script>doLiveChat("골드트리-${cookie.name}")</script>`)}
      {authenticated &&
        document.write(`<script
            src="https://kr03.tocplus007.com/chatLoader.do?userId=btsbts1111"
            type="text/javascript"
            ></script>`)}
    </>
  );
}

export default App;
