import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";
import Marquee from "react-fast-marquee";
import { isBrowser, isMobile } from "react-device-detect";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: gameView.none,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
    };
  }

  componentDidMount() {
    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        console.log(s.slot);
        this.setState({ slots: s.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
            item.push(casiono);
        }

        this.setState({
          casionos: item,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getMonth() + 1 + "월 " + yesterday.getDate() + "일";

    let tmp: any;
    tmp = [
      { id: "ths***", price: "86,000,000 원", date: yesterdayStr + " 14:59" },
      { id: "jvd***", price: "83,000,000 원", date: yesterdayStr + " 15:04" },
      { id: "vhr***", price: "82,000,000 원", date: yesterdayStr + " 07:01" },
      { id: "vdh***", price: "80,000,000 원", date: yesterdayStr + " 15:06" },
      { id: "df***", price: "78,000,000 원", date: yesterdayStr + " 08:08" },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlotList = () => {
    return (
      <>
        {this.state.slots.map((info: any, index: any) => (
          <a
            href="javascript:;"
            className="slot-btn"
            key={`key${info.nameEn}kkk`}
            style={{width : isMobile ? '48%' : '15%'}}
            onClick={() => {
              if (this.props.authenticated == false) {
                confirmAlert({
                  title: "로그인",
                  message: "로그인 이후 이용가능한 기능입니다",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {
                        this.setState({
                          mode: Mode.none,
                        });
                      },
                    },
                  ],
                });
                return;
              }

              if (info.used === "y") {
                this.setState({ gameCode: info.code });
                this.setState({ gameName: info.nameKo });
                this.setState({ mode: Mode.slot });
                this.handleSlotGames(info.code);
              } else {
                confirmAlert({
                  title: "점검중",
                  message: "현재 해당게임은 점검중입니다 .",
                  buttons: [
                    {
                      label: "확인",
                      onClick: () => {},
                    },
                  ],
                });
              }
            }}
          >
            <div className="main-cont">
              <div className="hover">
                <img className="hover-img" src={info.mobileImg} />
                <button className="show-btn">게임보기</button>
              </div>
              <img className="main-img" src={info.mobileImg} />
              <div className="foot">
                {info.nameEn}
                <p>{info.nameKo}</p>
              </div>
              {/* <div className="special-icon new">
                    <span>신규</span>
                  </div> */}
            </div>
          </a>
        ))}
      </>
    );
  };

  RenderSlotList2 = () => {
    return (
      <>
        <div className="game-menu">
          {this.state.slots.map((info: any, index: any) => (
            <a
              href="javascript:;"
              className={
                this.state.gameCode === info.code
                  ? "menu-link active"
                  : "menu-link"
              }
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            mode: Mode.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.handleSlotGames(info.code);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="img-cont">{info.nameEn}</div>
              <div className="text-cont">
                <span>{info.nameKo}</span>
              </div>
            </a>
          ))}
        </div>
        <div className="game-list">
          {this.state.slotgames.map((info: any, index: any) => (
            <a
              href="javascript:;"
              className="game-btn"
              onClick={() => {
                this.handleOpenSlot(info.code, info.gameCompany);
              }}
            >
              <div className="main-cont">
                <div className="card">
                  <div className="face front">
                    <img className="main-img" src={`${info.imgUrl}`} />
                  </div>
                  <div className="face back">
                    <button className="play-btn">게임시작</button>
                    <img className="main-img" src={`${info.imgUrl}`} />
                  </div>
                </div>
              </div>
              <div className="foot">
                <span>{info.nameKo}</span>
              </div>
            </a>
          ))}
        </div>
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
      {this.state.casionos.map((info: any, index: any) => (
        <a
          href="javascript:;"
          className="slot-btn"
          key={`key${info.nameEn}kkk`}
          style={{width : isMobile ? '48%' : '15%'}}
          onClick={() => {
            if (this.props.authenticated == false) {
              confirmAlert({
                title: "로그인",
                message: "로그인 이후 이용가능한 기능입니다",
                buttons: [
                  {
                    label: "확인",
                    onClick: () => {
                      this.setState({
                        mode: Mode.none,
                      });
                    },
                  },
                ],
              });
              return;
            }



                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }

          }}
        >
          <div className="main-cont">
            <div className="hover">
              <img className="hover-img" src={info.mobileImg} />
              <button className="show-btn">게임보기</button>
            </div>
            <img className="main-img" src={info.mobileImg} />
            <div className="foot">
              {info.nameEn}
              <p>{info.nameKo}</p>
            </div>
            {/* <div className="special-icon new">
                  <span>신규</span>
                </div> */}
          </div>
        </a>
      ))}
    </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== gameView.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices: any = [];
    let widhdraws = this.state.widhdraws;

    this.state.notices.map((info: any, index: any) => {
      if (index < 4) {
        notices.push(info);
      }
    });

    return (
      <>
        <div
          className="notice ani"
          animate-effect="zoomIn"
          animate-duration="0.3s"
          animate-delay="0.1s"
        >
          <h2>공지사항</h2>
          <ul>
            {notices.map((info: any, index: any) => (
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  [공지] {info.title}
                </a>
                <span className="date" style={{ color: "#666" }}>
                  {ConvertDate(info.regdate)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      return <div></div>;
    };

    return (
      <>
        <main className="page-content">
          <section className="slot-section">
            {/* <div className="withdraw-main">
              <div className="header">
                <img src="img/bg/grand-jackpot.png" />
              </div>
              <div className="container-main">
                <Marquee
                  direction="left"
                  delay={0}
                  gradientColor={[0, 0, 0]}
                  speed={80}
                >
                  <ul className="bs-ul">
                    {this.state.widhdraws.map((info: any, index: any) => (
                      <li>
                        <span className="count-icon">{index + 1}</span>
                        <span className="user">{info.id}</span>
                        <span className="amount">{info.price}</span>
                        <span className="date">{info.date}</span>
                      </li>
                    ))}
                  </ul>
                </Marquee>
              </div>
            </div> */}
            <div
              style={{
                display: this.state.mode === Mode.none ? "block" : "none",
              }}
            >
              
              {
                isMobile ?  <img src="img/slot.png" style={{padding: '30px', width: '90%'}}/> : <img src="img/slot.png" style={{padding: '30px'}}/>

              }
              <div className="slot-container">{this.RenderSlotList()}</div>
            </div>

            <div
              style={{
                display: this.state.mode === Mode.none ? "block" : "none",
              }}
            >
                          {
                isMobile ?  <img src="img/casino.png" style={{padding: '30px', width: '90%'}}/> : <img src="img/casino.png" style={{padding: '30px'}}/>

              }

              <div className="slot-container">{this.RenderCasinoGame()}</div>
            </div>

            
            <div
              style={{
                display: this.state.mode === Mode.slot ? "block" : "none",
              }}
            >
              <div className="gamelist-container">{this.RenderSlotList2()}</div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
